import React, { ReactElement } from 'react'
import { Container, Col } from 'react-bootstrap'
import Slider from 'react-slick'

import testimonial1 from 'src/assets/images/testimonio1.jpg'
import testimonial2 from 'src/assets/images/testimonio2.jpg'
import testimonial3 from 'src/assets/images/testimonio3.jpg'
import testimonial4 from 'src/assets/images/testimonio4.jpg'

import classes from './styles/Testimonials.module.scss'

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  variableWidth: false,
  speed: 300,
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        focusOnSelect: true,
        centerPadding: '5px',
        adaptiveHeight: true,
      },
    },
  ],
}

export const Testimonials = (): ReactElement => (
  <section id="testimoniales" className={classes.testimonials}>
    <Container>
      <h2>Testimoniales</h2>
      <div className="content-slider">
        <Slider {...settings}>
          <div className="card">
            <div className="text">
              Vivo en Capital pero soy de Concordia, TRIVU nos conecta, de forma más sencilla ,
              segura y rápida. ¡Súmate!
            </div>
            <div className="author">
              <Col>
                <img src={testimonial1} width="63" alt="testimonial 1" />
              </Col>
              <Col>
                <span className="name">Tomi Lombardi</span>
              </Col>
            </div>
          </div>
          <div className="card">
            <div className="text">
              Tengo 25 años, soy de Concordia, Entre Rios. Vivo y trabajo en Buenos Aires. Desde que
              conocí a carpooling viajo en mi vehículo seguido para ver a mi familia coordinando la
              salida como la vuelta. Una experiencia que realmente se las recomiendo!
            </div>
            <div className="author">
              <Col>
                <img src={testimonial2} width="63" alt="testimonial 2" />
              </Col>
              <Col>
                <span className="name">Miqueas</span>
              </Col>
            </div>
          </div>
          <div className="card">
            <div className="text">
              Me mudé a Concordia hace muy poco. Trabajo en casa y viajo mucho. Con TRIVU conocí la
              ciudad muy rápido, qué hacer y dónde ir, por las charlas copadas que se dan en la
              ruta, entre mate y mate.
            </div>
            <div className="author">
              <Col>
                <img src={testimonial3} width="63" alt="testimonial 3" />
              </Col>
              <Col>
                <span className="name">Natalia Moizzes</span>
              </Col>
            </div>
          </div>
          <div className="card">
            <div className="text">
              Estudio en Bs As, pero soy de Concordia y mi familia sigue viviendo allí, TRIVU me
              ayudó a llegar en pocas horas y segura a mi ciudad, conociendo gente increíble,
              haciendo el viaje mucho más llevadero.
            </div>
            <div className="author">
              <Col>
                <img src={testimonial4} width="63" alt="testimonial 4" />
              </Col>
              <Col>
                <span className="name">Rochi Lombardi</span>
              </Col>
            </div>
          </div>
        </Slider>
      </div>
    </Container>
  </section>
)
