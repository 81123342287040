import React from 'react'

import { Header } from './Header'
import { Footer } from './Footer'

interface Props {
  hiddenLinks?: boolean
}

export const Layout: React.FC<Props> = props => {
  const { children, hiddenLinks } = props
  return (
    <>
      <Header hiddenLinks={hiddenLinks} />
      {children}
      <Footer />
    </>
  )
}
