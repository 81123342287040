/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'

import { Layout } from 'src/components'

import { MiscApi } from './api'
import classes from './styles.module.scss'

export const TermsAndConditions = () => {
  const [termsAndConditions, setTermsAndConditions] = useState<string>()

  useEffect(() => {
    const fetch = async () => {
      const fetchedTermsAndConditions = await MiscApi.fetchTermsAndConditions()
      setTermsAndConditions(fetchedTermsAndConditions)
    }

    fetch()
  }, [])

  return (
    <Layout>
      <div
        dangerouslySetInnerHTML={{ __html: termsAndConditions ?? '' }}
        className={classes.container}
      />
    </Layout>
  )
}
