import React, { ReactElement } from 'react'
import { Nav, NavLink, NavbarBrand, Col, Row } from 'react-bootstrap'

import logoWhite from 'src/assets/images/logo-white.svg'
import logoFacebook from 'src/assets/social/Facebook.svg'
import logoInstagram from 'src/assets/social/Instagram.svg'

import classes from './styles/Footer.module.scss'

export const Footer = (): ReactElement => (
  <section className={classes.footer}>
    <nav className="navbar navbar-expand-lg">
      <NavbarBrand href="/">
        <img src={logoWhite} alt="Trivu" className="trivuLogo" />
      </NavbarBrand>
      <Nav className="navbar-nav ml-auto">
        <NavLink href="/#top">Home</NavLink>
        <NavLink href="/#que-es-trivu">¿Qué es Trivu?</NavLink>
        <NavLink href="/#como-funciona">¿Cómo Funciona?</NavLink>
        <NavLink href="/#beneficios">Beneficios</NavLink>
        <NavLink href="/#testimoniales">Testimoniales</NavLink>
      </Nav>
    </nav>
    <div className="bottom">
      <Row>
        <Col sm={6}>
          <p className="copyright">
            ® TRIVU . <a href="/terminos-y-condiciones">Términos y Condiciones</a> .{' '}
            <a href="/politicas-de-privacidad">Políticas de Privacidad</a> .
          </p>
        </Col>
        <Col sm={6} className="text-right">
          <a
            href="https://www.facebook.com/TRIVU-516058102225522/"
            className="social-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={logoFacebook} alt="Facebook" />
          </a>
          <a
            href="https://instagram.com/trivu.app?utm_source=ig_profile_share&igshid=xqffcdm4ygbh"
            className="social-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={logoInstagram} alt="Instagram" />
          </a>
        </Col>
      </Row>
    </div>
  </section>
)
