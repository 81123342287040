import { api } from 'src/api'

const fetchTermsAndConditions = async () => {
  const response = await api.get<string>('/misc/terms')
  return response.data
}

export const MiscApi = {
  fetchTermsAndConditions,
}
