import React from 'react'

import facebookLogo from 'src/assets/social/Facebook.svg'
import instagramLogo from 'src/assets/social/Instagram.svg'
import logoWhite from 'src/assets/images/logo-white.svg'

import classes from './styles.module.scss'

export const Footer: React.FC = () => (
  <footer className={classes.footer}>
    <a className={`navbar-brand ${classes.navbarBrand}`} href="/">
      <img src={logoWhite} alt="Trivu" />
    </a>
    <div className={classes.socialLinks}>
      <a
        href="https://www.facebook.com/TRIVU-516058102225522/"
        className={classes.socialLink}
        target="_blank"
        rel="noreferrer"
      >
        <img src={facebookLogo} alt="Facebook" />
      </a>
      <a
        href="https://instagram.com/trivu.app?utm_source=ig_profile_share&igshid=xqffcdm4ygbh"
        className={classes.socialLink}
        target="_blank"
        rel="noreferrer"
      >
        <img src={instagramLogo} alt="Instagram" />
      </a>
    </div>
    <div className={classes.bottomSection}>
      <p className={classes.copyright}>
        ® TRIVU .{' '}
        <a href="/terminos-y-condiciones" style={{ color: 'white', textDecoration: 'underline' }}>
          Términos y condiciones
        </a>{' '}
        .{' '}
        <a href="/politicas-de-privacidad" style={{ color: 'white', textDecoration: 'underline' }}>
          Políticas de Privacidad
        </a>{' '}
        .
      </p>
    </div>
  </footer>
)
