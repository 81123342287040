import React, { ReactElement } from 'react'
import { Jumbotron, Container } from 'react-bootstrap'

import banner from 'src/assets/hero/hero.jpg'
import GooglePlay from 'src/assets/images/GooglePlay.png'
import AppleStore from 'src/assets/images/AppleStore.png'

import classes from './styles/Hero.module.scss'

export const Hero = (): ReactElement => (
  <section id="hero">
    <Jumbotron className={classes.banner}>
      <img src={banner} className={classes.image} alt="banner" />
      <div className={classes.text}>
        <Container>
          <h2>Comenzá a moverte de manera inteligente</h2>
          <p>
            Bajate el app, conectate con gente que viaja al mismo lugar que vos y dividí los gastos.
          </p>
          <div className={classes.downloads}>
            <a
              href="https://itunes.apple.com/ar/app/trivu-app/id1447382915?l=en&mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppleStore} alt="Apple Store" width="180" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.ar.trivuapp&hl=es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={GooglePlay} alt="Google Play" width="180" />
            </a>
          </div>
        </Container>
      </div>
    </Jumbotron>
  </section>
)
