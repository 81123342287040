import React from 'react'
import { Link } from 'react-router-dom'

import logo from 'src/assets/images/logo.svg'

import classes from './styles.module.scss'

export const Header: React.FC = () => (
  <header>
    <div className="container">
      <nav className={`navbar navbar-expand-lg ${classes.navbar}`}>
        <a className="navbar-brand" href="/">
          <img src={logo} alt="Trivu" />
        </a>
        <div className="navbar-nav ml-auto">
          <Link to="/" className={`nav-item nav-link ${classes.navLink}`}>
            Conocé Trivu
          </Link>
        </div>
      </nav>
    </div>
  </header>
)
