import { api } from 'src/api'

const fetchPrivacyPolitics = async () => {
  const response = await api.get<string>('/misc/privacy')
  return response.data
}

export const MiscApi = {
  fetchPrivacyPolitics,
}
