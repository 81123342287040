import React, { ReactElement } from 'react'
import { Container, Col, Row } from 'react-bootstrap'

import dolar from 'src/assets/images/Dolar.svg'
import timer from 'src/assets/images/timer.svg'
import share from 'src/assets/images/Share.svg'
import semaforo from 'src/assets/images/semaforo.svg'

import classes from './styles/Benefits.module.scss'

export const Benefits = (): ReactElement => (
  <section id="beneficios">
    <div className={classes.beneficios}>
      <div className="skyline">
        <Container>
          <h2>Beneficios</h2>
          <p>
            Viajar con TRIVU tiene ventajas tanto para vos como para tu ciudad y el medio ambiente.
          </p>
          <div className="benef-list">
            <Row className="text-center">
              <Col md={3} className="item">
                <img src={dolar} alt="" />
                <h4>Ahorrá dinero</h4>
                <p>Compartí todos los gastos de tu viaje con tus compañeros de ruta.</p>
              </Col>
              <Col md={3} className="item">
                <img src={timer} alt="" />
                <h4>Ahorrá tiempo</h4>
                <p>Olvidate de esperar un colectivo o ir a una terminal, subís al auto y listo.</p>
              </Col>
              <Col md={3} className="item">
                <img src={share} alt="" />
                <h4>Viajá acompañado</h4>
                <p>
                  Conocé personas que tengan que ir a tu mismo destino, tendrás con quien hablar y
                  compartir unos mates.
                </p>
              </Col>
              <Col md={3} className="item">
                <img src={semaforo} alt="" />
                <h4>Ayudá a reducir tráfico</h4>
                <p>
                  Al compartir autos, habrá menos tráfico en la ciudad y en las rutas, impactando
                  positivamente al medio ambiente.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  </section>
)
