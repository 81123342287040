import React from 'react'

import erroricon from 'src/assets/favicon/error-icon.png'

import classes from './Error.module.scss'

const Error: React.FC = () => {
  return (
    <div className={classes.bkg}>
      <h4 className={classes.titleError}>Tenemos un problema</h4>
      <div className={classes.iconContainer}>
        <img src={erroricon} alt="error icon" />
      </div>
      <span className={classes.text}>Ha ocurrido un error, volve a intentarlo!</span>
    </div>
  )
}

export default Error
