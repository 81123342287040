import React, { useState } from 'react'
import { Nav, Navbar, NavLink, Container, Row } from 'react-bootstrap'
import classnames from 'classnames'

import logo from 'src/assets/images/logo.svg'

import classes from './styles/Header.module.scss'

interface Props {
  hiddenLinks?: boolean
}

export const Header: React.FC<Props> = props => {
  const [showMenu, setShowMenu] = useState(false)

  const { hiddenLinks } = props

  const toggleMenu = () => {
    setShowMenu(current => !current)
  }

  const onClickedLink = () => {
    // do something
  }

  const menuClass = showMenu ? 'menu-opened' : 'menu-closed'

  return (
    <>
      <header id="top">
        <Nav activeKey="1" className={classes.header}>
          <Navbar>
            <Container>
              <a href="/" className="navbar-logo mr-auto">
                <img src={logo} alt="Trivu" />
              </a>
              <button
                aria-label="menu"
                className={classnames('navbar-toggler', menuClass)}
                type="button"
                onClick={toggleMenu}
              />
              <Row className={classnames('ml-auto navbar-dropdown', menuClass)}>
                <NavLink href="/#top" eventKey="1" onSelect={onClickedLink}>
                  Home
                </NavLink>
                {!hiddenLinks && (
                  <>
                    <NavLink href="/#que-es-trivu" eventKey="2" onSelect={onClickedLink}>
                      ¿Qué es Trivu?
                    </NavLink>
                    <NavLink href="/#como-funciona" eventKey="3" onSelect={onClickedLink}>
                      ¿Cómo Funciona?
                    </NavLink>
                    <NavLink href="/#beneficios" eventKey="4" onSelect={onClickedLink}>
                      Beneficios
                    </NavLink>
                    <NavLink href="/#testimoniales" eventKey="5" onSelect={onClickedLink}>
                      Testimoniales
                    </NavLink>
                  </>
                )}
              </Row>
            </Container>
          </Navbar>
        </Nav>
      </header>
    </>
  )
}
