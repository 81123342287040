import React, { useEffect, useState } from 'react'

import { PrefApi } from './api'
import Error from './components/Error'
import { initializePaymentBrick } from './initializePaymentBrick'

const API_KEY = process.env.REACT_APP_API_KEY_MERCADOPAGO

function useMercadoPago() {
  const [mercadoPago, setMercadoPago] = useState()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://sdk.mercadopago.com/js/v2'

    script.addEventListener('load', () => {
      // @ts-ignore
      setMercadoPago(new window.MercadoPago(API_KEY, { locale: 'es-AR' }))
    })

    document.body.appendChild(script)

    return () => {
      const iframe = document.body.querySelector('iframe[src*="mercadolibre"]')

      if (iframe) {
        document.body.removeChild(iframe)
      }

      document.body.removeChild(script)
    }
  }, [])

  return mercadoPago
}

export const MercadoPagoCheckout: React.FC = () => {
  const [error, setError] = useState(false)
  const mercadopago = useMercadoPago()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const price = params.get('price')
    const token = params.get('token')
    const userid = params.get('userid')
    const tripid = params.get('tripid')

    async function initPaymentBrick() {
      if (!mercadopago) return
      if (!price) {
        setError(true)
        return
      }
      const priceNumber = parseInt(price, 10)
      try {
        if (!priceNumber) return
        const preferencesid = await PrefApi.createPreferences({
          items: [
            {
              title: 'Comisión Trivu',
              quantity: 1,
              unit_price: priceNumber,
            },
          ],
          installments: 1,
          transaction_amount: priceNumber,
          token,
          metadata: { user_id: userid, trip_id: tripid },
          auto_return: 'all',
          back_urls: {
            success: `trivu://mercado/${tripid}`,
            failure: `trivu://mercado/${tripid}`,
            pending: `trivu://mercado/${tripid}`,
          },
        })
        // @ts-ignore
        await initializePaymentBrick(mercadopago.bricks(), price, preferencesid)
      } catch (err) {
        console.log(err)
        setError(true)
      }
    }

    initPaymentBrick()
  }, [mercadopago])

  return (
    <>
      {error && <Error />}
      <div id="paymentBrick_container" />
    </>
  )
}
