import React, { ReactElement } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Slider from 'react-slick'

import step1 from 'src/assets/images/step1.png'
import step2 from 'src/assets/images/step2.png'
import step3 from 'src/assets/images/step3.png'

import classes from './styles/HowItWorks.module.scss'

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  fade: true,
  speed: 300,
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        focusOnSelect: true,
        centerPadding: '10px',
      },
    },
  ],
}

export const HowItWorks = (): ReactElement => (
  <section id="como-funciona">
    <div className={classes.howItWorks}>
      <Container>
        <Row>
          <Col md={4}>
            <h2>¿Como funciona TRIVU?</h2>
          </Col>
          <Col md={8}>
            <Slider {...settings}>
              <div className="align-items-center">
                <img src={step1} alt="How it works - step 1" />
                <div className="text">
                  <h3>El conductor crea su viaje</h3>
                  <p>
                    Indica cuando y de dónde sale, hacia dónde va, lugares disponibles en el auto y
                    el costo por persona.... muy simple!
                  </p>
                </div>
              </div>
              <div className="align-items-center">
                <img src={step2} alt="How it works - step 2" />
                <div className="text">
                  <h3>El pasajero busca un viaje</h3>
                  <p>
                    Selecciona origen, destino y cuándo quiere viajar. TRIVU muestra las opciones
                    disponibles y le permite elegir la que mejor se ajuste a sus preferencias y
                    presupuesto.
                  </p>
                </div>
              </div>
              <div className="align-items-center">
                <img src={step3} alt="How it works - step 3" />
                <div className="text">
                  <h3>Viajan y califican la experiencia</h3>
                  <p>
                    Conductor y pasajero coordinan el viaje mediante el chat de TRIVU. Al llegar al
                    destino ambos califican su experiencia, manteniendo la comunidad segura y
                    confiable.
                  </p>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  </section>
)
