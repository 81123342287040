import React, { ReactElement } from 'react'
import { Container } from 'react-bootstrap'

import classes from './styles/About.module.scss'

export const About = (): ReactElement => (
  <section>
    <div id="que-es-trivu" className={classes.about}>
      <Container>
        <h2>¿Qué es Trivu?</h2>
        <p>
          TRIVU es una plataforma para compartir viajes, ya sea para ir a otra ciudad o hasta para
          ir a la oficina. Moverse de manera inteligente es ahorrar gastos, tiempo, conocer gente
          nueva y cuidar el medioambiente. Podés ser el conductor o sumarte a un viaje ya creado.{' '}
        </p>
      </Container>
    </div>
  </section>
)
