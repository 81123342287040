import React, { ReactElement } from 'react'

import { Layout } from 'src/components'

import { Hero, About, HowItWorks, Security, Benefits, Testimonials } from './components'

export const HomePage = (): ReactElement => (
  <div>
    <Layout>
      <Hero />
      <About />
      <HowItWorks />
      <Security />
      <Benefits />
      <Testimonials />
    </Layout>
  </div>
)
