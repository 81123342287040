import React, { ReactElement } from 'react'
import { Col, Row } from 'react-bootstrap'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import seguridad from 'src/assets/images/seguridad.jpg'

import classes from './styles/Security.module.scss'

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  fade: true,
  speed: 300,
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        focusOnSelect: true,
        centerPadding: '10px',
      },
    },
  ],
}

export const Security = (): ReactElement => (
  <section id="security" className={classes.security}>
    <div className="box-two-columns">
      <Row noGutters>
        <Col md={6}>
          <div className="box-two-columns">
            <img className="full-image" src={seguridad} alt="" />
          </div>
        </Col>
        <Col md={6}>
          <Slider {...settings}>
            <div className="align-items-center">
              <h2>
                Seguridad y <br className="d-none d-md-block" />
                confianza
              </h2>
              <h3>Vos elegís con quién viajas</h3>
              <p>
                Como conductor podés aceptar o rechazar las solicitudes que te envían para unirse a
                tu viaje.
              </p>
              <p>Como pasajero elegís con qué conductor viajar y ves quiénes son sus pasajeros.</p>
            </div>
            <div className="align-items-center">
              <h2>Seguridad y confianza</h2>
              <h3>Ves la calificación de los usuarios y su información</h3>
              <p>Verificá la reputación de cada usuario en base a viajes anteriores</p>
              <p>
                Leé las opiniones de otros usuarios sobre el conductor. Analizá la información que
                TRIVU te muestra sobre cada usuario
              </p>
            </div>
            <div className="align-items-center">
              <h2>Seguridad y confianza</h2>
              <h3>Te comunicás antes del viaje</h3>
              <p>
                Podés utilizar el chat de TRIVU para hablar con tus compañeros de viaje y conocerse
                antes de salir
              </p>
            </div>
          </Slider>
        </Col>
      </Row>
    </div>
  </section>
)
