import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import appleStoreLogo from 'src/assets/images/AppleStore.png'
import googlePlayLogo from 'src/assets/images/GooglePlay.png'
import avatarImg from 'src/assets/images/user-placeholder.png'
import avatarImg2X from 'src/assets/images/user-placeholder@2x.png'

import { TripApi } from './api'
import { Header, Footer } from './components'
import classes from './styles.module.scss'

export const ShareTrip: React.FC = () => {
  // TODO: Type correctly
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [trip, setTrip] = useState<any | null>(null)
  const [loading, setLoading] = useState(true)
  const { tripId } = useParams<{ tripId: string | undefined }>()

  const fetchTrip = useCallback(async () => {
    if (tripId) {
      try {
        setLoading(true)
        const tripResponse = await TripApi.fetchTripById(tripId)
        setTrip(tripResponse)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
  }, [tripId])

  useEffect(() => {
    fetchTrip()
  }, [fetchTrip])

  const renderHeader = () => {
    if (!trip) {
      if (loading) {
        return (
          <h2>
            Viaje a
            <img
              src="https://samherbert.net/svg-loaders/svg-loaders/three-dots.svg"
              alt="Cargando..."
            />
          </h2>
        )
      }

      return <h2>No se encontró el viaje</h2>
    }

    return <h2>{`Viaje a ${trip?.destination_city}`}</h2>
  }

  const renderRating = () => {
    const rating = trip?.driver?.global_rating
    if (!rating) return null

    return (
      <>
        {Array(rating).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <i key={index} className="icon-star" />
        ))}
        {Array(5 - rating).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <i key={index} className="icon-star-outline" />
        ))}
      </>
    )
  }

  return (
    <div>
      <Header />
      <main className={classes.tripContainer}>
        <div className={`container ${classes.container}`}>
          {renderHeader()}
          <div style={{ opacity: loading ? 0.2 : 1 }} className={`card ${classes.card} mb-8`}>
            <div className={`${classes.route} mb-8`}>
              <div className={classes.point}>
                <h4>Origen</h4>
                <p>
                  {trip
                    ? `${trip?.origin_address}, ${trip?.origin_city}, ${trip?.origin_region}`
                    : '-'}
                </p>
              </div>
              <div className={classes.point}>
                <h4>Destino</h4>
                <p>
                  {trip
                    ? `${trip?.destination_address}, ${trip?.destination_city}, ${trip?.destination_region}`
                    : '-'}
                </p>
              </div>
            </div>
          </div>
          <div style={{ opacity: loading ? 0.2 : 1 }} className={`card ${classes.card}`}>
            <div className={classes.date}>
              <div className="row">
                <div className="col">
                  <i className="icon-calendar" />
                  <span>{trip?.start_date ? dayjs(trip.start_date).format('D/MM/YYYY') : '-'}</span>
                </div>
                <div className="col">
                  <i className="icon-clock" />
                  <span>{trip?.start_date ? dayjs(trip.start_date).format('h:mm a') : '-'}</span>
                </div>
              </div>
            </div>
          </div>
          <div style={{ opacity: loading ? 0.2 : 1 }} className={`card ${classes.card}`}>
            <div className={classes.driver}>
              {trip?.driver?.profile_pic ? (
                <img src={trip.driver.profile_pic} alt={trip?.driver?.full_name ?? 'Foto perfil'} />
              ) : (
                <img
                  src={avatarImg}
                  srcSet={`${avatarImg} 1x, ${avatarImg2X} 2x`}
                  alt={trip?.driver?.full_name ?? 'Perfil'}
                />
              )}

              <div className={classes.driverInfo}>
                <h3>{trip?.driver.full_name ?? '-'}</h3>
                <p>
                  Conductor
                  <span className={classes.driverReviews}>{renderRating()}</span>
                </p>
              </div>
            </div>
          </div>
          <div style={{ opacity: loading ? 0.2 : 1 }} className={`card ${classes.card}`}>
            <div className={classes.cost}>
              <div className="row">
                <div className="col">
                  <p>Costo por pasajero</p>
                </div>
                <div className="col text-right">
                  <span className={classes.price}>{`$ ${trip?.charge_per_passenger ?? '-'}`}</span>
                </div>
              </div>
            </div>
          </div>
          {!!trip?.comment && (
            <div key="comment" className={`card ${classes.card}`}>
              <div className={classes.comment}>{trip.comment}</div>
            </div>
          )}
          <div className={classes.connectContainer}>
            <h3>Unite a este viaje</h3>
            <p>
              Para sumarte a este viaje, descargá en tu smartphone la app de Trivu y hace click
              nuevamente en el enlace compartido.
            </p>
            <div className={classes.downloads}>
              <a
                href="https://itunes.apple.com/ar/app/trivu-app/id1447382915?l=en&mt=8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appleStoreLogo} alt="Apple Store" width="180" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.ar.trivuapp&hl=es"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={googlePlayLogo} alt="Google Play" width="180" />
              </a>
            </div>
          </div>
          <div className={`${classes.connectContainer} ${classes.openTravel}`}>
            <h3>
              Ya tenés la app? Abrí el viaje desde acá{' '}
              <span role="img" aria-label="Abrir viaje">
                👇
              </span>
            </h3>
            <a href={`trivu://viaje/${trip?.id}`}>Abrir viaje</a>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}
