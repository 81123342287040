//  @ts-ignore
const postMessage = data => window.ReactNativeWebView.postMessage(JSON.stringify({ data }))

export const initializePaymentBrick = async (bricksBuilder, price, preferenceid) => {
  const settings = {
    initialization: {
      amount: price,
      preferenceId: preferenceid,
      redirectMode: 'blank',
    },
    customization: {
      visual: {
        style: {
          customVariables: {
            textPrimaryColor: '#999999',
            // errorColor: 'red', // TODO: Color error input to be defined
            baseColor: '#FF5F5F',
          },
        },
      },
      locale: 'es-AR',
      paymentMethods: {
        mercadoPago: ['wallet_purchase'],
        creditCard: 'all',
        debitCard: 'all',
        minInstallments: 1,
        maxInstallments: 1,
      },
    },
    callbacks: {
      onReady: () => {},
      onSubmit: async cardFormData => {
        const paymentData = {
          ...cardFormData,
          formValues: {
            ...cardFormData.formData,
            capture: cardFormData.paymentType !== 'credit_card',
          },
          type: 'success',
        }
        postMessage(paymentData)
      },
      onError: error => {
        if (error.cause === 'missing_payment_information') {
          console.log(error)
        } else {
          postMessage({ ...error, type: 'error' })
        }
      },
      appSwitch: {
        enabled: true,
        path: '/checkout/payment',
      },
      useNativeApp: true,
    },
  }
  // @ts-ignore
  window.paymentBrickController = await bricksBuilder.create(
    'payment',
    'paymentBrick_container',
    settings,
  )
}
